/* FILTER BOX - START */

.dr-page .dr-filterbox .panel-heading {
    padding: 0;
}

.dr-page .dr-filterbox .panel-heading {
    padding: 10px;
    width: 100%;
    display: table;
    text-align: left;
    font-size: 16px;
}

.dr-page .dr-filterbox .panel-heading.panel-collapsedin {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.dr-page .dr-filterbox .panel-heading .header-text {
    display: table-cell;
    line-height: 30px;
}

.dr-page .dr-filterbox .panel-heading .header-text svg {
    margin-right: 8px;
}

.dr-page .dr-filterbox .panel-heading .header-buttons {
    display: table-cell;
    text-align: right;
}

.dr-page .dr-filterbox .panel-heading .header-buttons button {
    margin-left: 15px;
}

.dr-page .dr-filterbox .dr-selector .Select--multi .Select-value {
    font-size: 0.7em;
}

.dr-page .dr-external-col {
    padding-left: 0;
    padding-right: 0;
}

/* FILTER BOX - END */

/* DR-ALERT - START */

.dr-page .dr-alert {
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 15px;
}

.dr-page .dr-alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
}

.dr-page .dr-alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

/* DR-ALERT - END */

/* DATE-SELECT - START */

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    color: #f0f8ff;
    background-color: #4a90e2;
}

.Selectable .DayPicker-Day {
    border-radius: 0 !important;
    border: 1px solid #eaecec;
}

/* DATE-SELECT - END */

.dr-external-col {
    padding-left: 0;
    padding-right: 0;
}

.dr-filters strong, .dr-selector strong {
    font-size: 12px;
}

.dr-filters strong {
    padding: 0 15px
}

.dr-filters .btn {
    font-size: 14px;
    padding: 4px 12px 4px 12px;
}

.dr-page .dr-dimension-head .btn {
    font-size: 11.8px;
    padding: 6px 8px;
}

.dr-filters .filter-grid.table {
    margin-bottom: 0px;
}

.dr-selector {
    margin-bottom: 10px;
}

.dr-dimension-head .Select-input, .dr-selector .Select-input {
    height: 28px;
}

.dr-dimension-head .Select-value, .dr-selector .Select-value {
    line-height: 16px;
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 2px;
}

.dr-dimension-head .Select-control, .dr-selector .Select-control {
    height: 28px;
    overflow: auto
}

.dr-dimension-head .Select-option, .dr-selector .Select-option {
    font-size: 12px;
    padding: 5px 10px;
}

.dr-dimension-head .Select--single>.Select-control .Select-value, .dr-selector .Select--single>.Select-control .Select-value {
    line-height: 22px;
    font-size: 12px;
}

.dr-selector .Select-placeholder {
    font-size: 12px;
    line-height: 30px;
}

.dr-dimension-head {
    display: table;
    position: relative;
    width: 100%;
}

.dr-dimension-head .dr-dimension-left {
    display: table-cell;
    position: relative;
    width: 100%;
    vertical-align: bottom;
}

.dr-dimension-head .dr-dimension-right {
    display: table-cell;
    float: right;
    vertical-align: bottom;
    white-space: nowrap;
}

.dr-selector .Select-menu-outer, .dr-dimension-head .Select-menu-outer {
    z-index: 5;
}

.spin-centered {
    margin: 0 auto;
}

.dr-grid .ag-theme-balham.table.table-bordered.table-follow-up {
    position: relative;
}

.dr-grid .suppress-dr-grid {
    color: #b9b9b9
}